<template>
	<div class="random">
		<div class="random-left">
			<div>
				<span class="title">成本计算器</span>
			</div>
			<el-row class="line">
				<el-col :span="24">
					<table class="password-table">
						<tr id="investment">
							<td class="td1"><span>交易方式</span></td>
							<td class="td2" colspan="2">
								<el-radio-group v-model="model" size="mini" @change="count()">
									<el-radio-button label="买入"></el-radio-button>
									<el-radio-button label="卖出"></el-radio-button>
								</el-radio-group>
							</td>
						</tr>
						<tr id="investTimeLimit">
							<td class="td1"><span>原成本</span></td>
							<td class="td2" colspan="2">
								<el-input v-model="sourceCost" @input="initCostCount()" placeholder="请输入" maxlength="12" size="small"></el-input>
							</td>
						</tr>
						<tr id="investCountMode">
							<td class="td1"><span>剩余成本</span></td>
							<td class="td2" colspan="2">
								<el-input v-model="residueCost" @input="initCostCount()" placeholder="请输入" maxlength="12" size="small"></el-input>
							</td>
						</tr>
						<tr id="investAgain">
							<td class="td1"><span>初始成本</span></td>
							<td class="td2" colspan="2">
								<el-input v-model="initCost" @input="count()" placeholder="请输入" maxlength="12" size="small"></el-input>
							</td>
						</tr>
						<tr id="investRate" ref="investRate">
							<td class="td1"><span>初始股数</span></td>
							<td class="td2">
								<el-input v-model.number="initQuantity" @input="count()" placeholder="请输入" maxlength="12" size="small"></el-input>
							</td>
							<td class="td3">
								<el-radio-group v-model="initQuantityUnit" size="mini" @change="count()">
									<el-radio-button label="手"></el-radio-button>
									<el-radio-button label="股"></el-radio-button>
								</el-radio-group>
							</td>
						</tr>
						<tr id="investWanOfIncome">
							<td class="td1"><span>初始金额</span></td>
							<td class="td2" colspan="2">
								{{initMoney}}
							</td>
						</tr>
						<tr id="investIncomeInterest" ref="investIncomeInterest">
							<td class="td1"><span>					<span v-if="model=='买入'">买入价格</span>
					<span v-if="model=='卖出'">卖出价格</span></span></td>
							<td class="td2" colspan="2">
								<el-input v-model="stockPrice" @input="count()" placeholder="请输入" maxlength="12" size="small"></el-input>
							</td>
						</tr>
						<tr id="investIncomeTotal">
							<td class="td1"><span><span v-if="model=='买入'">买入数量</span>
					<span v-if="model=='卖出'">卖出数量</span></span></td>
							<td class="td2" >
								<el-input v-model.number="stockQuantity" @input="count()" placeholder="请输入" maxlength="12" size="small"></el-input>
							</td>
							<td>
								<el-radio-group v-model="stockQuantityUnit" size="mini" @change="count()">
									<el-radio-button label="手"></el-radio-button>
									<el-radio-button label="股"></el-radio-button>
								</el-radio-group>
							</td>
						</tr>
						<tr id="investIncomeTotal">
							<td class="td1"><span> 所需金额</span></td>
							<td class="td2" colspan="">
								{{stockMoney}}
							</td>
							<td class="td2" colspan="">

							</td>
						</tr>
						<tr id="investIncomeTotal">
							<td class="td1"><span> 手续费率</span></td>
							<td class="td2" colspan="">
								<el-input v-model.number="serviceFeeRate" @input="count()" placeholder="请输入" maxlength="12" size="small"></el-input>
							</td>
							<td class="td2" colspan="">
								<el-button type="text" size="mini" @click="detailSign=!detailSign">详细</el-button>
							</td>
						</tr>
						<tr id="investIncomeTotal" v-if="detailSign">
							<td class="td1"><span> 手续费</span></td>
							<td class="td2" colspan="">
								{{serviceFee}}
							</td>
							<td class="td2" colspan="">

							</td>
						</tr>
						<tr id="investIncomeTotal" v-if="detailSign">
							<td class="td1"><span>过户费率 </span></td>
							<td class="td2" colspan="">
								<el-input v-model.number="transferFeeRate" @input="count()" placeholder="请输入" maxlength="12" size="small"></el-input>
							</td>
							<td class="td2" colspan="">

							</td>
						</tr>
						<tr id="investIncomeTotal" v-if="detailSign">
							<td class="td1"><span> 过户费</span></td>
							<td class="td2" colspan="">
								{{transferFee}}
							</td>
							<td class="td2" colspan="">

							</td>
						</tr>
						<tr id="investIncomeTotal" v-if="detailSign">
							<td class="td1"><span> 印花税率</span></td>
							<td class="td2" colspan="">
								<el-input v-model.number="stampTaxRate" @input="count()" placeholder="请输入" maxlength="12" size="small"></el-input>
							</td>
							<td class="td2" colspan="">

							</td>
						</tr>
						<tr id="investIncomeTotal" v-show="model=='卖出' && detailSign">
							<td class="td1"><span> 印花税</span></td>
							<td class="td2" colspan="">
								{{stampTax}}
							</td>
							<td class="td2" colspan="">

							</td>
						</tr>
						<tr id="investIncomeTotal" v-if="model=='卖出'">
							<td class="td1"><span> 卖出获得</span></td>
							<td class="td2" colspan="">
								{{gainMoney}}
							</td>
							<td class="td2" colspan="">

							</td>
						</tr>
						<tr id="investIncomeTotal">
							<td class="td1"><span> 最终股本</span></td>
							<td class="td2" colspan="">
								{{resultCost}}
							</td>
							<td class="td2" colspan="">

							</td>
						</tr>
						<tr id="investIncomeTotal">
							<td class="td1"><span> 最终股数</span></td>
							<td class="td2" colspan="">
								{{resQuantity}}
							</td>
							<td class="td2" colspan="">

							</td>
						</tr>
						<tr id="investIncomeTotal">
							<td class="td1"><span> 最终成本</span></td>
							<td class="td2" colspan="">
								{{resMoney}}
							</td>
							<td class="td2" colspan="">

							</td>
						</tr>
						<tr id="investIncomeTotal" v-if="model=='买入'">
							<td class="td1"><span> 保本卖出价</span></td>
							<td class="td2" colspan="">
								{{suggestBuyPrice}}
							</td>
							<td class="td2" colspan="">

							</td>
						</tr>
					</table>
				</el-col>
			</el-row>
			<el-row style="margin-top: 20px" v-if="adSwitch">
				<el-col :span="24" style="text-align: center">
					<div class="ad" style="height: 90px;text-align: center;"></div>
				</el-col>
			</el-row>
			<el-row style="margin-top: 20px">
				<el-col :span="24" style="text-align: center">
					<el-image :src="pwd_img_url" width="95%" style="max-width: 550px;border-radius: 10px;"></el-image>
				</el-col>
			</el-row>
			<el-row class="about">
				<el-col :span="24">
					<el-collapse v-model="activeNames" @change="handleChange">
						<el-collapse-item title="关于利息计算器" name="1">
							<div>
								<p> 利息计算器是一种非常实用的工具，它可以帮助人们计算各种贷款、存款和投资的利息。通过输入相关的信息，比如贷款金额、利率、还款期限等，利息计算器可以快速地计算出每月需要还款的金额和总利息。同样地，对于存款和投资，利息计算器可以帮助人们预测未来的收益，并帮助人们做出更明智的决策。</p>
								<p>  利息计算器的使用非常简单，只需要输入相关信息，就可以得到准确的结果。而且，利息计算器的使用范围非常广泛，不仅可以用于个人的贷款和投资，还可以用于企业的融资和投资。因此，利息计算器是一种非常实用的工具，可以帮助人们更好地管理自己的财务，做出更明智的决策。</p>
							</div>
						</el-collapse-item>
					</el-collapse>
				</el-col>
			</el-row>
		</div>
		<div class="random-right">
			<div style=" height: 250px; margin-bottom: 8px; text-align: center; " v-if="adSwitch">

			</div>
			<div>
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>推荐工具</span>
					</div>
					<div v-for="(item,index) in recommendList" :key="index" style="margin-bottom: 10px">
						<a :href="item.url" target="_blank" style="color: #3a8ee6;">{{ item.name }}</a>
					</div>
				</el-card>
			</div>
		</div>
	</div>
</template>

<script>
import Table from "@/views/Test.vue";

export default {
	components: {Table},
	metaInfo: {
		title: '利息计算器 - 免费在线利息计算工具',
		meta: [
			{
				charset: "utf-8"
			},
			{
				name: 'description,',
				content: '这是一款完全免费的在线利息计算器,可帮您快速计算投资本金、投资期限和收益率下的应得利息和总金额。无需注册,输入少量信息即可得出结果。方便实用,欢迎使用。'
			},
			{
				name: 'keywords',
				content: '利息计算器,在线利息计算工具,投资利息计算,本金利息计算,免费利息计算器'
			}],
		link: [
			{
				rel: 'canonical',
				href: 'https://www.itptg.com/invest/'
			}
		]
	},
	data() {
		return {
			pwd_img_url: require("@/assets/images/chengben.jpg"),
			activeNames: ['1'],
			adSwitch: false,
			recommendList: [
				{'name': '随机密码生成', 'url': '/'},
				{'name': '利息计算器', 'url': '/invest/'},
				{'name': '成本计算器', 'url': '/stockcost/'},
				{'name': '编码转换', 'url': '/stockcost/'}
			],

			model: '买入',
			initCost: 0,
			initQuantity: 0,
			initQuantityUnit: '股',
			stockPrice: 0,
			stockQuantity: 0,
			stockQuantityUnit: '股',
			serviceFeeRate: 0.00025,
			transferFeeRate: 0.00001,
			stampTaxRate: 0.001,
			gainMoney: 0,
			resMoney: 0,
			resultCost: 0,
			suggestBuyPrice: 0,
			detailSign: false,
			sourceCost:0,
			residueCost:0,
		}
	},
	computed: {
		initQuantitys(){
			if(this.initQuantityUnit == '股'){
				return this.initQuantity;
			}else if(this.initQuantityUnit == '手'){
				return this.initQuantity*100;
			}
		},
		stockQuantitys(){
			if(this.stockQuantityUnit == '股'){
				return this.stockQuantity;
			}else if(this.stockQuantityUnit == '手'){
				return this.stockQuantity*100;
			}
		},

		serviceFee(){
			//手续费 = （股票价格*股票数量）* 手续费率
			let res = (this.stockPrice * this.stockQuantitys) * this.serviceFeeRate;
			if(res > 5){
				return this.approach(res,1000);
			}else{
				return 5;
			}
		},
		transferFee(){
			//过户费 = （股票价格*股票数量）* 过户费率
			let res = (this.stockPrice * this.stockQuantitys) * this.transferFeeRate;
			return this.approach(res,100);
		},
		stampTax(){
			//印花税 = （股票价格*股票数量）* 印花税率
			let res = (this.stockPrice * this.stockQuantitys) * this.stampTaxRate;
			return this.approach(res,100);
		},
		resQuantity(){
			if(this.model=='买入'){
				//股票数量 =（初始数量 + 买入数量）
				return this.initQuantitys + this.stockQuantitys;
			}else if(this.model=='卖出'){
				//股票数量 =（初始数量 - 买入数量）
				return this.initQuantitys - this.stockQuantitys;
			}
		},

		initMoney(){
			let initMoney;
			if(this.initQuantitys==0){
				// 初始数量为0时, 初始金额 = 初始成本
				initMoney = this.initCost;
			}else{
				//初始金额 = 初始成本*初始数量
				initMoney = this.initCost * this.initQuantitys;
			}
			return this.approach(initMoney,1000);
		},
		stockMoney(){
			//股票价值 = 股票价格*股票数量
			let stockMoney = this.stockPrice * this.stockQuantitys;
			return this.approach(stockMoney,1000);
		},
	},
	methods: {
		handleChange(val) {
			console.log(val);
		},
		initCostCount(){
			if(this.sourceCost!=0){
				this.initCost = this.approach(this.sourceCost - this.residueCost,100);
			}
		},
		count(){
			if(this.model=="买入"){
				if(this.stockQuantitys==0){
					//最终成本 = （（初始成本*初始数量）
					this.resMoney = this.initMoney;
					//最终股本 = 初始数量
					this.resultCost = this.initCost;
				}else if(this.resQuantity>0){
					//最终成本 = （（初始成本*初始数量） +（股票价格*股票数量）+ 手续费 + 过户费）
					this.resMoney = this.initMoney + this.stockMoney + this.buyCharge(this.stockMoney);
					//最终股本 = （（初始成本*初始数量） +（股票价格*股票数量）+ 手续费 + 过户费）/ （初始数量+买入数量）
					this.resultCost = this.resMoney / this.resQuantity;
				}else{
					this.resultCost = 0;
				}

				if(this.stockMoney>0){
					//保本卖出价格 = 成本价 + 卖出手续费
					this.buyPrice(this.resultCost, this.initQuantitys + this.stockQuantitys, 0.01);
				}else{
					this.suggestBuyPrice = 0;
				}
				//四舍五入
				this.suggestBuyPrice = this.approach(this.suggestBuyPrice,1000);
			}else if(this.model=="卖出"){
				if(this.stockMoney==0){
					this.gainMoney = 0;
				}else{
					//卖出获得 = （股票价格*股票数量）- 手续费 - 印花税 - 过户费
					this.gainMoney = this.stockMoney - this.saleCharge(this.stockMoney);
				}

				//最终成本 = （初始成本*初始数量） -（（股票价格*股票数量）- 手续费 - 印花税 - 过户费）
				this.resMoney = this.initMoney - this.gainMoney;

				if(this.stockQuantitys==0){
					//最终股本 = 初始数量
					this.resultCost = this.initCost;
				}else if(this.resQuantity>0){
					//最终股本 = （（初始成本*初始数量） -（（卖出价格*卖出数量）- 手续费 - 印花税 - 过户费））/ （初始数量-卖出数量）
					this.resultCost = this.resMoney / this.resQuantity;
				}else{
					this.resultCost = 0;
				}
				//四舍五入
				this.gainMoney = this.approach(this.gainMoney,1000);
			}
			//四舍五入
			this.resMoney = this.approach(this.resMoney,1000);
			this.resultCost = this.approach(this.resultCost,1000);
		},
		//买入手续费 = 手续费(万分之2.5) + 过户费(万分之0.1)
		buyCharge(stockMoney){
			return this.serviceFee + this.transferFee;
		},
		//卖出手续费 = 手续费(万分之2.5) + 过户费(万分之0.1) + 印花税(千分1)
		saleCharge(stockMoney){
			return this.serviceFee + this.transferFee + this.stampTax;
		},
		//保本卖出价格 = 成本价 + 卖出手续费
		buyPrice(stockMoney, stockQuantitys, increment){
			let totalMoney = stockMoney * stockQuantitys;
			let saleMoney = (stockMoney + increment) * stockQuantitys;
			console.log(totalMoney+","+saleMoney+","+this.saleCharge(saleMoney));
			if((saleMoney - totalMoney)>= this.saleCharge(saleMoney)){
				this.suggestBuyPrice = saleMoney/stockQuantitys;
			}else{
				this.buyPrice(stockMoney, stockQuantitys, increment+0.01);
			}
		},
	}
}
</script>

<style scoped>


@media screen and (min-width: 800px) {
	.random {
		width: 100%;
		text-align: left;
		display: flex;
		justify-content: space-between;
		border: 1px dashed #cccccc;
	}

	.random-left {
		margin: 5px;
		border: 1px solid #000000;
		flex-basis: 67%;
	}

	.random-left .line {
		border-top: 1px solid #000000;
	}

	.random-left .about {
		margin: 10px;
	}

	.title {
		font-weight: 700;
		font-size: 32px;
		display: inline-block;
		width: 100%;
		text-align: center;
		line-height: 80px;
		height: 80px;
	}

	.password-table {
		border-collapse: collapse;
		max-width: 450px;
		margin: 0 auto;
		margin-top: 10px;
		font-size: 12px;
	}

	.password-table td {
		border: 1px solid black;
		padding: 8px;
	}

	.random-right {
		flex-basis: 33%;
		margin: 5px;
	}

}

@media screen and (max-width: 799px) {
	.random {
		width: 100%;
		text-align: left;
		display: flex;
		flex-direction: column;
	}

	.random-left {
		margin: 5px;
		flex-basis: 100%;
	}

	.title {
		font-weight: 700;
		font-size: 32px;
		display: inline-block;
		width: 100%;
		text-align: center;
		line-height: 80px;
		height: 80px;
	}

	.password-table {
		border-collapse: collapse;
		max-width: 450px;
		margin: 0 auto;
		margin-top: 10px;
		font-size: 12px;
	}

	.password-table td {
		border: 1px solid black;
		padding: 8px;
	}

	.random-right {
		display: none;
	}
}
</style>
